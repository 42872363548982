.App {
  background-color: #f5f5f5;
  min-height: 100%;
  font-family: 'Lato', sans-serif;
  color: #2c384e;
}

.App-header {
  background-color: #282c34;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24pt;
  color: #f5f5f5;
  font-family: 'Consolas', Courier, monospace;
}

.App-sub-header {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 3%;
}

.App-section-header {
  background-color: #DBDBDB;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2% 10% 2% 10%;
  color: #2c384e;
  font-family: 'Consolas', monospace;
  font-weight: bold;
}

.App-body {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 3% 10% 3% 10%;
}

.App-link {
  color: #61dafb;
}

.App-card {
  background-image: linear-gradient(to bottom right, #485563, #29323c);
  box-shadow: 5px 5px 20px gray;
  border-radius: 4pt;
  padding: 3%;
  margin: 3% 0% 3% 0%;
  min-width: 100%;
  align-content: flex-start;
  color: #f5f5f5;
}

.card-title {
  font-weight: bold;
  font-family: 'Patua One', cursive;
}

.container {
  display: flex;
  justify-content: flex-start;
  min-width: 50%;
  padding: 1em 0em 1em 0em;
}

.badge {
  display: inline-block;
  border-radius: 4pt;
  color: white;
  font-size: medium;
  font-family: 'Consolas', monospace;
  margin-right: 0.5em;
}

.badge-container {
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.cpp {
  background-color: #0086D4;
}

.csharp {
  background-color: #9A4993;
}

.xaml {
  background-color: #1751AB;
}

.python {
  background-color: #17ab61;
}

.php {
  background-color: #777CB5;
}

.html {
  background-color: #E44D26;
}

.css {
 background-color: #285EA8;
}

.javascript {
  background-color: #D5B930;
}

.minor-shadow {
  box-shadow: 3px 3px 15px black;
}

a {
  color: #29817a;
}

a:hover {
  color: #30a79d;
}

ul {
  display: flex;
  flex-direction: column;
  line-height: 30pt;
  list-style-type: circle;
}

li {
  font-size: larger;
}

img {
  margin: 0.5em;
  max-width: 30%;
  height: auto;
}

.wide-image {
  max-width: 45%;
}

.wider-image {
  max-width: 60%;
}

button {
  background-color: #e7e7e7;
  color: black;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border: none;
  border-radius: 4pt;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
}

button:hover {
  background-color: #4c93af;
  color: white;
}

button:active {
  background-color: #336477;
  transform: translateY(4px);
}
